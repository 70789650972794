'use strict';

import Alpine from 'alpinejs';
import api from './api';

const licenseApiBaseUrl = "https://api.aikeedo.com/licenses";

export function installerView() {
    Alpine.data('installer', (
        version = 'dev',
        serverIp = null,
    ) => ({
        step: 'welcome',
        model: {
            license: null,
            db: {
                driver: 'pdo_mysql',
                host: 'localhost',
                port: 3306,
                name: 'aikeedo',
                user: null,
                password: null,
            },
            account: {
                first_name: null,
                last_name: null,
                email: null,
                password: null
            },
            ratios: {
                token: 0.0001875,
                image: 2,
                audio: 0.2475,
            },
            migrate: null,
            hasData: null,
        },
        isProcessing: false,
        requirements: null,
        error: null,

        init() {
            this.$refs.loading.remove();
        },

        view(step) {
            this.error = null;
            this.step = step;
        },

        viewRequirement() {
            this.isProcessing = true;

            api.get('/requirements')
                .then((resp) => resp.json())
                .then((resp) => {
                    this.isProcessing = false;
                    this.requirements = resp;

                    this.view(this.step == 'requirements' && this.requirements.is_satisfied ? 'license' : 'requirements');
                });
        },

        submitLicenseForm() {
            this.isProcessing = true;
            this.error = null;

            // Check license here
            fetch(`${licenseApiBaseUrl}/${this.model.license}`)
                .then(async (resp) => {
                    this.isProcessing = false;

                    if (resp.ok) {
                        this.view('db');
                        return;
                    }

                    try {
                        let data = await resp.json();
                        this.error = data.message || 'Invalid license key';
                    } catch (error) {
                        this.error = 'Unable to verify license key';
                    }
                })
                .catch(error => {
                    this.isProcessing = false;
                    this.error = 'Unexpected error occurred! Please try again later.';
                    console.error(error);
                });
        },

        submitDbForm() {
            this.isProcessing = true;
            this.error = null;

            api.post('/database', this.model.db)
                .then(resp => resp.json())
                .then(resp => {
                    this.isProcessing = false;

                    this.model.migrate = resp.migrate;
                    this.model.hasData = resp.has_data;
                    this.view(this.model.migrate ? 'migration' : 'account');
                })
                .catch(error => {
                    this.isProcessing = false;
                    this.error = 'Unexpected error occurred! Please try again later.';
                    console.error(error);

                    error.json().then(
                        resp => this.error = resp.message || this.error
                    );
                });
        },

        async install() {
            this.isProcessing = true;

            try {
                await api.post('/env', this.model)
                await api.post('/database/scheme', {
                    migrate: this.model.migrate
                });

                if (this.model.migrate) {
                    await api.post('/database/data', {
                        ratios: this.model.ratios
                    });
                } else {
                    await api.post('/users', this.model.account);
                }

                await api.post('/presets/import');
                await api.post('/activate', { license: this.model.license });
            } catch (error) {
                this.isProcessing = false;
                this.error = 'Unexpected error occurred! Please try again later.';
                this.view('failure');
                console.error(error);

                error.json().then(
                    resp => this.error = resp.message || this.error
                );

                return;
            }

            let ip = null;
            try {
                let resp = await fetch('https://freeipapi.com/api/json/');
                let ipinfo = await resp.json();

                ip = ipinfo.ipAddress;
            } catch (error) {
                console.error(error);
            }

            // Activate license here
            try {
                await fetch(`${licenseApiBaseUrl}/${this.model.license}/activate`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        'license': this.model.license,
                        'domain': window.location.hostname,
                        'server_ip': serverIp,
                        'client_ip': ip,
                        'email': this.model.account.email,
                        'first_name': this.model.account.first_name,
                        'last_name': this.model.account.last_name,
                        'version': version
                    }),
                });
            } catch (error) {
                console.error(error);
            }

            this.isProcessing = false;
            this.view('success');
        }
    }))
}